<template>
  <div class="grid grid-cols-2 min-h-screen">
    <div class="bg-center bg-cover border-r-2 flex flex-col left-pane m-6 p-15 rounded-lg">
      <div class="flex flex-col grow items-start justify-center text-white">
        <!-- eslint-disable vue/no-v-html -->
        <div class="flex h-40 items-center justify-start w-full">
          <div class="font-bold leading-tight text-6xl z-20" v-html="$t('auth.title')" />
        </div>
        <div class="font-normal max-w-120 mt-8 text-lg z-20" v-html="$t('auth.pitch')" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <div>
      <!-- <Nuxt /> -->
      <slot />
    </div>
    <toast-notifications />
  </div>
</template>

<script>
import ToastNotifications from '~/components/notifications/ToastNotifications';

export default {
  name: 'AuthLayout',

  components: {
    ToastNotifications,
  },

  created() {
    useHead({
      meta: [{ name: 'build-number', content: this.$config.public.buildNumber }],
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Crux` : 'Welcome - Crux';
      },
    });
  },
};
</script>

<style scoped>
.left-pane {
  background-image: url('~/assets/img/crux-login-background.png');
}
</style>
